import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
/* import FestivalSens from './components/FestivalSens'; */
import Sommaire from "./components/Sommaire";
import Partenaire from "./components/Partenaire";
import Footer from "./components/Footer";

function App() {
	var themeRec = localStorage.getItem("theme");
	var theme2 = themeRec.substr(1);
	var theme = theme2.substring(0, theme2.length - 1);

	return (
		<div className="App" data-theme={theme}>
			<Header />
			{/* <FestivalSens /> */}
			<Sommaire />
			<Partenaire />
			<Footer />
		</div>
	);
}

export default App;
