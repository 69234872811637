import React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Footer from "./Footer";
import Adultes from "./Adultes";
import Ecole from "./Ecole";

function Entrainement() {
	var themeRec = localStorage.getItem("theme");
	var theme2 = themeRec.substr(1);
	var theme = theme2.substring(0, theme2.length - 1);

	return (
		<div className="Entrainement" data-theme={theme}>
			<Header />
			<Adultes />
			<Ecole />
			<Footer />
		</div>
	);
}

export default Entrainement;
