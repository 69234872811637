import React from "react";
import "../App.css";
import { Card, Row, Col, Table } from "react-bootstrap";
import Willy from "../images/willy.jpg";
//import Mickael from "../images/mickael.jpg";
//import Alex from "../images/alex.jpg";
import Ludovic from "../images/ludovic.jpg";
import Sebastien from "../images/sebastien.jpg";
//import ArnaudT from "../images/arnaudt.jpg";
import Antoine from "../images/antoine.jpg";
import Course from "../images/course.png";
import CourseDark from "../images/coursedark.png";
import Velo from "../images/velo.png";
import VeloDark from "../images/velodark.png";
import Natation from "../images/natation.png";
import NatationDark from "../images/natationdark.png";
//import Renfo from "../images/renfo.png";
//import RenfoDark from "../images/renfodark.png";
//import KeepCool from "../images/keepcool.svg";

function Ecole() {
	var themeRec = localStorage.getItem("theme");
	var theme2 = themeRec.substr(1);
	var theme = theme2.substring(0, theme2.length - 1);

	return (
		<div className="Ecole" id="ecole">
			<h1>L'école de triathlon</h1>
			<hr />
			<div className="OneCard">
				<Card className="CardStyle2 Card-ombre">
					<Card.Body>
						<Card.Text className="textMentions justify">
							<div className="alinea">
								Depuis plus de 10 ans, le club se structure pour
								développer une école de triathlon destinée à la
								formation de jeunes triathlètes de 9 à 18 ans.
								Pour cela, le club dispose de six BF2 dont l'un
								est titulaire du BEESAN et quatre du BNSSA, afin
								d’assurer un encadrement de qualité.
							</div>
							<br />
							<div className="alinea">
								Du matériel et des programmes d’entraînement
								spécifiques ont été mis en place, des créneaux
								réservés aux jeunes afin de garantir une plus
								grande efficacité de l’enseignement. L’école de
								Triathlon a été labélisée « Ecole de Triathlon 2
								étoiles » par la Fédération Française de
								Triathlon en 2022 et participe chaque année aux test
								Classe Triathlon organisée par la FFTRI.
							</div>
						</Card.Text>
						<Card.Title className="titleMentions padding-creneaux">
							Les créneaux d'entrainements
						</Card.Title>

						{/* <Card.Subtitle className="pink">
							À partir de 8 ans
						</Card.Subtitle> */}

						<Table className="tableau-responsive" responsive>
							<thead>
								<tr>
									<th className="all-border">Jour</th>
									{/* <th className="blue-back all-border">
										Lundi au Vendredi
									</th> */}
									<th
										colspan="1"
										className="blue-back all-border"
									>
										Samedi
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td
										className="vertical bold side-border"
										rowSpan="2"
									>
										Discipline
									</td>
									{/* <td className="vertical side-border">
										<img
											height="45px"
											className="petite-image"
											alt="Natation"
											src={
												theme === "dark"
													? NatationDark
													: Natation
											}
										/>
									</td> */}
									<td className="vertical side-border">
										<img
											height="45px"
											className="petite-image"
											alt="Natation"
											src={
												theme === "dark"
													? NatationDark
													: Natation
											}
										/>
										<img
											height="45px"
											className="petite-image"
											alt="Velo"
											src={
												theme === "dark"
													? VeloDark
													: Velo
											}
										/>
										<img
											height="45px"
											className="petite-image"
											alt="Course"
											src={
												theme === "dark"
													? CourseDark
													: Course
											}
										/>
									</td>
									
								</tr>
								<tr>
									{/* <td className="blue-text side-border vertical">
										Savoir nager 50m
									</td> */}
									
									<td className="pink-text side-border vertical">
										Ecole de triathlon
									</td>
								</tr>
								<tr>
									<td className="bold side-border">
										Horaire
									</td>
									{/* <td className="blue-text side-border vertical">
										-
									</td> */}
									<td className="pink-text side-border vertical">
										13h45
									</td>
									
								</tr>
								<tr>
									<td className="bold side-bottom-border">Lieu</td>
									{/* <td className="blue-text side-bottom-border vertical">
										Centre nautique
									</td> */}
									<td className="pink-text side-bottom-border vertical">
										Centre nautique (ou instructions par mail)
									</td>
								</tr>
								
							</tbody>
						</Table>

						{/* <Card.Subtitle className="pink">
							De la 6ème à la 4ème
						</Card.Subtitle>


						<Table className="tableau-responsive" responsive>
							<thead>
								<tr>
									<th className="all-border">Jour</th>
									<th className="blue-back all-border">
										Vendredi
									</th>
									<th
										colspan="1"
										className="pink-back all-border"
									>
										Samedi
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td
										className="vertical bold side-border"
										rowSpan="2"
									>
										Discipline
									</td>
									<td className="vertical side-border">
										<img
											height="45px"
											className="petite-image"
											alt="Natation"
											src={
												theme === "dark"
													? NatationDark
													: Natation
											}
										/>
									</td>
									<td className="vertical side-border">
										<img
											height="45px"
											className="petite-image"
											alt="Natation"
											src={
												theme === "dark"
													? NatationDark
													: Natation
											}
										/>
										<img
											height="45px"
											className="petite-image"
											alt="Velo"
											src={
												theme === "dark"
													? VeloDark
													: Velo
											}
										/>
										<img
											height="45px"
											className="petite-image"
											alt="Course"
											src={
												theme === "dark"
													? CourseDark
													: Course
											}
										/>
									</td>
									
								</tr>
								<tr>
									<td className="blue-text side-border vertical">
										Natation
									</td>
									
									<td className="pink-text side-border vertical">
										Ecole de triathlon
									</td>
								</tr>
								<tr>
									<td className="bold side-border">
										Horaire
									</td>
									<td className="blue-text side-border vertical">
										18h30
									</td>
									<td className="pink-text side-border vertical">
										13h45
									</td>
									
								</tr>
								<tr>
									<td className="bold side-bottom-border">Lieu</td>
									<td className="blue-text side-bottom-border vertical">
										Centre nautique
									</td>
									<td className="pink-text side-bottom-border vertical">
										Centre nautique (ou instructions par mail)
									</td>
								</tr>
								
							</tbody>
						</Table> */}

						{/* <Card.Subtitle className="pink">
							3ème et lycéens
						</Card.Subtitle>

						<Table className="tableau-responsive" responsive>
				<thead>
					<tr>
						<th className="all-border">Jour</th>
						<th className="blue-back all-border">Lundi</th>
						<th className="pink-back all-border">Mardi</th>
						<th className="blue-back all-border">Mercredi</th>
						<th className="pink-back all-border">Jeudi</th>
						<th className="blue-back all-border">Vendredi</th>
						<th className="pink-back all-border">Samedi</th>
						<th className="blue-back all-border">Dimanche</th>
					</tr>
				</thead>
				<tbody>
					
					<tr>
						<td className="vertical bold side-border" rowSpan="2">
							Discipline
						</td>
						<td className="vertical side-border">
							<img
								height="45px"
								className="petite-image"
								alt="Natation"
								src={theme === "dark" ? NatationDark : Natation}
							/>
						</td>
						<td className="vertical side-border">
							<img
								height="45px"
								className="petite-image"
								alt="Natation"
								src={theme === "dark" ? NatationDark : Natation}
							/>
						</td>
						<td className="vertical side-border">
						<img
								height="45px"
								className="petite-image"
								alt="Course"
								src={theme === "dark" ? CourseDark : Course}
							/>
							<img
								height="45px"
								className="petite-image"
								alt="Renfo"
								src={theme === "dark" ? RenfoDark : Renfo}
							/>
						</td>
						<td className="vertical side-border">
							<img
								height="45px"
								className="petite-image"
								alt="Course"
								src={theme === "dark" ? CourseDark : Course}
							/>
						</td>
						<td className="vertical side-border">
							<img
								height="45px"
								className="petite-image"
								alt="Natation"
								src={theme === "dark" ? NatationDark : Natation}
							/>
						</td>

						<td className="vertical side-border">
										<img
											height="45px"
											className="petite-image"
											alt="Natation"
											src={
												theme === "dark"
													? NatationDark
													: Natation
											}
										/>
										<img
											height="45px"
											className="petite-image"
											alt="Velo"
											src={
												theme === "dark"
													? VeloDark
													: Velo
											}
										/>
										<img
											height="45px"
											className="petite-image"
											alt="Course"
											src={
												theme === "dark"
													? CourseDark
													: Course
											}
										/>
									</td>
						
						<td className="vertical side-border">
							<img
								height="45px"
								className="petite-image"
								alt="Velo"
								src={theme === "dark" ? VeloDark : Velo}
							/>
						</td>
					</tr>
					<tr>
						<td className="blue-text side-border vertical">
							Natation
						</td>
						<td className="pink-text side-border vertical">
							Natation
						</td>
						<td className="blue-text side-border vertical">
							Footing + PPG / Renfo
						</td>
						<td className="pink-text side-border vertical">
							CàP libre
						</td>
						<td className="blue-text side-border vertical">
							Natation
						</td>
						<td className="blue-text side-border vertical">
							Groupe ado
						</td>
						<td className="blue-text side-border vertical">Vélo</td>
					</tr>
					<tr>
						<td className="bold side-border">Horaire</td>
						<td className="blue-text side-border vertical">
							20h
						</td>
						<td className="pink-text side-border vertical">
							20h15
						</td>
						<td className="blue-text side-border vertical">
							18h
						</td>
						<td className="pink-text side-border vertical">
							18h30
						</td>
						<td className="blue-text side-border vertical">
							19h45
						</td>
						<td className="blue-text side-border vertical">
							9h
						</td>
						<td className="blue-text side-border vertical">9h30</td>
					</tr>
					<tr>
						<td className="bold  side-bottom-border">Lieu</td>
						<td className="blue-text  side-bottom-border vertical">
							Centre nautique
						</td>
						<td className="pink-text  side-bottom-border vertical">
							Centre nautique
						</td>
						<td className="blue-text  side-bottom-border vertical">
							<img
								height="20px"
								className=""
								alt="KeepCool"
								src={KeepCool}
							/>
						</td>
						<td className="pink-text  side-bottom-border vertical">
						
							Stade Claude Pitou
						</td>
						<td className="blue-text  side-bottom-border vertical">
							Centre nautique
						</td>
						<td className="blue-text  side-bottom-border vertical">
							Centre nautique
						</td>
						<td className="blue-text  side-bottom-border vertical">
							Parking Centre nautique
						</td>
					</tr>
					
				</tbody>
			</Table> */}


						{/* <Table className="tableau-responsive" responsive>
							<thead>
								<tr>
									<th className="all-border">Jour</th>
									<th className="blue-back all-border">
										Vendredi
									</th>
									<th
										colspan="2"
										className="pink-back all-border"
									>
										Samedi
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td
										className="vertical bold side-border"
										rowSpan="2"
									>
										Discipline
									</td>
									<td className="vertical side-border">
										<img
											height="45px"
											className="petite-image"
											alt="Natation"
											src={
												theme === "dark"
													? NatationDark
													: Natation
											}
										/>
									</td>
									<td className="vertical side-border">
										<img
											height="45px"
											className="petite-image"
											alt="Natation"
											src={
												theme === "dark"
													? NatationDark
													: Natation
											}
										/>
										<img
											height="45px"
											className="petite-image"
											alt="Velo"
											src={
												theme === "dark"
													? VeloDark
													: Velo
											}
										/>
										<img
											height="45px"
											className="petite-image"
											alt="Course"
											src={
												theme === "dark"
													? CourseDark
													: Course
											}
										/>
									</td>
									<td className="vertical side-border">
										<img
											height="45px"
											className="petite-image"
											alt="Natation"
											src={
												theme === "dark"
													? NatationDark
													: Natation
											}
										/>
										<img
											height="45px"
											className="petite-image"
											alt="Velo"
											src={
												theme === "dark"
													? VeloDark
													: Velo
											}
										/>
										<img
											height="45px"
											className="petite-image"
											alt="Course"
											src={
												theme === "dark"
													? CourseDark
													: Course
											}
										/>
									</td>
								</tr>
								<tr>
									<td className="blue-text side-border vertical">
										Natation
									</td>
									<td className="pink-text side-border vertical">
										Enchainement Natation + Vélo ou CàP
									</td>
									<td className="pink-text side-border vertical">
										Natation / Vélo / CàP
									</td>
								</tr>
								<tr>
									<td className="bold side-border">
										Horaire
									</td>
									<td className="blue-text side-border vertical">
										19h45 - 21h15
									</td>
									<td className="pink-text side-border vertical">
										9h15 - 12h
									</td>
									<td className="pink-text side-border vertical">
										13h30 - 16h
									</td>
								</tr>
								<tr>
									<td className="bold side-border">Lieu</td>
									<td className="blue-text side-border vertical">
										Centre nautique
									</td>
									<td className="pink-text side-border vertical">
										Piscine Tournesol
									</td>
									<td className="pink-text side-border vertical">
										Piscine Tournesol
									</td>
								</tr>
								<tr>
									<td className="bold side-bottom-border">
										Encadrement
									</td>
									<td className="blue-text side-bottom-border vertical">
										Mickael et Alex
									</td>
									<td className="pink-text side-bottom-border vertical">
										Ludo D (Groupe ado)
									</td>
									<td className="pink-text side-bottom-border vertical">
										Moniteurs de l'école de triathlon
									</td>
								</tr>
							</tbody>
						</Table> */}
					</Card.Body>
				</Card>
			</div>

			<h1>Les éducateurs</h1>
			<hr />
			<Row className="descendre-ligne">
				<Col xs={12} md={6} lg={3}>
					<img className="BureauIMG" alt="Willy Noury" src={Willy} />
					<h2>Willy NOURY</h2>
					<p className="pink">BF2 et BEESAN</p>
				</Col>
				{/* <Col xs={12} md={6} lg={3}>
					<img
						className="BureauIMG"
						alt="Mickaël Meunier"
						src={Mickael}
					/>
					<h2>Mickaël MEUNIER</h2>
					<p className="pink">BF5 et BNSSA</p>
				</Col>
				<Col xs={12} md={6} lg={3}>
					<img
						className="BureauIMG"
						alt="Alexandre Meunier"
						src={Alex}
					/>
					<h2>Alexandre MEUNIER</h2>
					<p className="pink">BF5 et BNSSA</p>
				</Col> */}
				<Col xs={12} md={6} lg={3}>
					<img
						className="BureauIMG"
						alt="Ludovic Duhautoy"
						src={Ludovic}
					/>
					<h2>Ludovic DUHAUTOY</h2>
					<p className="pink">BF2 et BNSSA</p>
				</Col>
				{/* <Col xs={12} md={12} lg={1}></Col> */}
				<Col xs={12} md={6} lg={3}>
					<img
						className="BureauIMG"
						alt="Sébastien Canada"
						src={Sebastien}
					/>
					<h2>Sébastien CANADA</h2>
					<p className="pink">BF2 et BNSSA</p>
				</Col>
				{/* <Col xs={12} md={6} lg={4}>
					<img
						className="BureauIMG"
						alt="Arnaud Thibault"
						src={ArnaudT}
					/>
					<h2>Arnaud THIBAULT</h2>
					<p className="pink">BF2</p>
				</Col> */}
				<Col xs={12} md={12} lg={3}>
					<Row>
						<Col xs={12} md={4} lg={12}></Col>
						<Col xs={12} md={4} lg={12}>
							<img
								className="BureauIMG"
								alt="Antoine Lair"
								src={Antoine}
							/>
							<h2>Antoine LAIR</h2>
							<p className="pink">BF2</p>
						</Col>
						<Col xs={12} md={4} lg={12}></Col>
					</Row>
				</Col>
				<Col xs={12} md={12} lg={1}></Col>
			</Row>
		</div>
	);
}

export default Ecole;
