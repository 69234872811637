import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import Entrainement from "./components/Entrainement";
import Club from "./components/Club";
import Organisations from "./components/Organisations";
import Dys from "./components/Dys";
import Mentions from "./components/Mentions";
import Contact from "./components/Contact";
import Triathlon from "./components/Triathlon";
import BikeAndRun from "./components/BikeAndRun";
/* import Message from './components/Message'; */
/* import Connexion from './components/Connexion'; */
/* import Leaflet from './components/Leaflet'; */

/* import Login from './components/Login/Login' */

import NoMatch from "./components/Error404/Error404.js";

const Root = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path="/" component={App} />
			<Route exact path="/organisations" component={Organisations} />
			<Route exact path="/triathlon" component={Triathlon} />
			<Route exact path="/bikeandrun" component={BikeAndRun} />
			<Route exact path="/club" component={Club} />
			<Route exact path="/entrainements" component={Entrainement} />
			<Route exact path="/projet" component={Dys} />
			<Route exact path="/mentions" component={Mentions} />
			<Route exact path="/contact" component={Contact} />
			{/*       <Route exact path='/login' component={Login} /> */}
			{/*       <Route exact path='/maps' component={Leaflet} /> */}

			{/* Anciennes routes */}
			<Route
				exact
				path="/presentation-du-bike-and-run"
				component={Organisations}
			/>
			<Route
				exact
				path="/triathlon-du-senonais/parcours"
				component={Organisations}
			/>
			<Route
				exact
				path="/triathlon-du-senonais"
				component={Organisations}
			/>
			<Route exact path="/mentions-legales" component={Mentions} />

			       {/* <Route exact path='/message' component={Message} /> */}
    {/*  <Route exact path='/connexion' component={Connexion} /> */}

			<Route>
				<NoMatch />
			</Route>
		</Switch>
	</BrowserRouter>
);

ReactDOM.render(
	<React.StrictMode>
		<Root />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
