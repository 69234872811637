import React from "react";
import "../App.css";
import { Card } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";

function Dys() {
	var themeRec = localStorage.getItem("theme");
	var theme2 = themeRec.substr(1);
	var theme = theme2.substring(0, theme2.length - 1);

	return (
		<div className="Dys" id="dys" data-theme={theme}>
			<Header />
			<h1>Sport en ville & mobilité en toute sécurité</h1>
			<hr />
			<div className="OneCard">
				<Card className="CardStyle2 Card-ombre">
					<Card.Body>
						<Card.Text className="textMentions justify">
							<div className="alinea">
								Depuis le lancement de l'opération Sport en
								Ville par la Ville de Sens, Sens Triathlon
								participent en proposant des créneaux
								d'entrainement de découverte. Au cours de ce
								projet, les triathlètes ont pu constater que
								beaucoup d'accidents impliquent des trottinettes
								et des cyclistes qui ne sont pas équipés de
								casques ou d'éclairages, alors même que leur
								efficacité n'est plus à démontrer et que leur
								usage est maintenant communément acquis dans les
								pratiques des triathlètes quel que soit leur
								âge. Aussi, le club participera à sensibiliser
								les usagers de vélo et trottinettes aux impacts
								du port du casque et éclairage.
							</div>
						</Card.Text>
					</Card.Body>
				</Card>
			</div>
			<h1>
				Le triathlon pour tenir la distance : 1 sport, 3 disciplines
				pour dépasser les troubles DYS !
			</h1>
			<hr />
			<div className="OneCard">
				<Card className="CardStyle2 Card-ombre">
					<Card.Body>
						<Card.Text className="textMentions justify">
							<div className="alinea">
								Le nombre d’enfants / jeunes atteints de
								troubles DYS est estimé à environ 8 à 10 % des
								enfants, majoritairement des garçons. Ces
								situations de handicap invisibles seraient en
								nombre supérieur aux situations de handicap
								visible. Pour autant, d’une part, elles ne
								bénéficient pas de structures pleinement
								consacrées à ce type de trouble. Aussi, le
								développement des aptitudes par le biais d’une
								pratique sportive adaptée et reposant sur la
								mixité entre personnes valides et personnes en
								situation de handicap est de nature à favoriser
								l’implication des adolescents dans leur propre
								trajectoire de progrès.{" "}
							</div>
							<br />• Objectif principal :
							<div className="alinea">
								Développer les aptitudes des enfants/jeunes en
								situation de troubles DYS pour leur permettre
								une meilleure insertion sociale et scolaire.{" "}
							</div>
							<br />• Objectifs secondaires :
							<div className="alinea">
								Permettre à des enfants et des jeunes atteints
								de troubles DYS d’accéder à une activité
								complémentaire à l’accompagnement médico-social
								habituel.{" "}
							</div>
							<br />
							<div className="alinea">
								Les enfants atteints de troubles DYS ont
								d’importants besoins d’accompagnement en termes
								de psychomotricité, alors même que cet aspect,
								non remboursé par la sécurité sociale, peut être
								sacrifié par les familles. La pratique du
								triathlon est dans ce cas un complément qui
								permet de répondre aux besoins liés à la
								psychomotricité. En effet, la pratique des trois
								enchainées (natation, cyclisme, course à pied)
								développe plus particulièrement des aptitudes
								qui font défaut dans les situations de troubles
								DYS :{" "}
							</div>
							<br />
							• Développer :<br />
							<div className="alinea">
								- la proprioception (conscience du corps dans
								son environnement, son espace)
							</div>
							<div className="alinea">- la posture</div>
							<div className="alinea">- l’équilibre</div>
							<div className="alinea">- la respiration</div>
							<div className="alinea">- la confiance en soi</div>
							<div className="alinea">- l’organisation</div>
							<div className="alinea">
								- les repérages dans l’espace : exercice de
								latéralité et d’orientation, automaticité des
								mouvements
							</div>
							<div className="alinea">- la concentration</div>
							<br />
							<div className="alinea">
								De plus, d’une façon générale, comme toute
								pratique sportive associative, la pratique du
								triathlon s’appuie sur des valeurs d’engagement,
								de respect, de solidarité. La mixité entre
								athlètes valides et athlètes pour lesquels il y
								a une prise en compte d’un handicap invisible
								permet de renforcer l’insertion et la cohésion
								sociale{" "}
							</div>
							<br />• Publics cibles :
							<div className="alinea">
								12 Enfants/jeunes de 10 à 15 ans -
								Préadolescents – Adolescents
							</div>
						</Card.Text>
					</Card.Body>
				</Card>
			</div>
			<Footer />
		</div>
	);
}

export default Dys;
