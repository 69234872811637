import React, { Component } from "react";
import "../App.css";
import { Table } from "react-bootstrap";
import Convivialite from "../images/convivialite.png";
import Solidarite from "../images/solidarite.png";
import Perseverance from "../images/perseverance.png";

class Projet extends Component {
	render() {
		return (
			<div className="Projet" id="projet">
				<h1>Le projet associatif</h1>
				<hr />
				<h2>“ Seul on va plus vite, ensemble on va plus loin. ”</h2>
				<div className="tableau-container">
					<Table className="tableau-projet" responsive>
						<thead>
							<tr>
								<th className="blue-back side-top-border big-table-title">
									Convivialité
								</th>
								<th className="pink-back side-top-border big-table-title">
									Solidarité
								</th>
								<th className="blue-back side-top-border big-table-title">
									Persevérance
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="blue-back side-bottom-border vertical">
									<img
										className="ProjetIMG"
										alt="Convivialité"
										src={Convivialite}
									/>
								</td>
								<td className="pink-back side-bottom-border vertical">
									<img
										className="ProjetIMG"
										alt="Solidarité"
										src={Solidarite}
									/>
								</td>
								<td className="blue-back side-bottom-border vertical">
									<img
										className="ProjetIMG"
										alt="Persevérance"
										src={Perseverance}
									/>
								</td>
							</tr>
							<tr>
								<td className="blue-text side-bottom-border justify">
									L’objectif de cette association sportive est
									de créer des espaces de rencontre et
									d’échanges autour d’un objectif commun. Elle
									permet à des personnes de différents
									horizons et niveaux de pratique sportive de
									se rencontrer.
								</td>
								<td className="pink-text side-bottom-border vertical justify">
									Le principe du club est de reposer sur
									l’engagement de chacun : participation à
									l’organisation des épreuves du club,
									accompagnement des jeunes de l’école de
									triathlon, recherche de sponsors...
								</td>
								<td className="blue-text side-bottom-border vertical justify">
									La pratique du triathlon permet de
									travailler sur la coordination, l’endurance,
									la concentration... Elle permet tant aux
									jeunes qu’aux adultes de structurer leurs
									efforts, de dépasser leurs propres limites
									et elle permet d’acquérir une plus grande
									confiance en soi.
								</td>
							</tr>
						</tbody>
					</Table>
				</div>
			</div>
		);
	}
}

export default Projet;
