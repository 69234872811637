import React, { Component } from "react";
import "../App.css";

class Licence extends Component {
	render() {
		return (
			<div className="Licence" id="licence">
				<h1>Se licencier</h1>
				<hr />
				<h2>
					La prise de licence à Sens Triathlon se fait sur le site
					Espace Tri de la fédération.
				</h2>
				<a
					href="https://espacetri.fftri.com/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<p>Cliquez ici pour vous inscrire!</p>
				</a>
			</div>
		);
	}
}

export default Licence;
