import React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Footer from "./Footer";
import Bureau from "./Bureau";
import Projet from "./Projet";
import Origine from "./Origine";
import Licence from "./Licence";

function Club() {
	var themeRec = localStorage.getItem("theme");
	var theme2 = themeRec.substr(1);
	var theme = theme2.substring(0, theme2.length - 1);

	return (
		<div className="Club" data-theme={theme}>
			<Header />
			<Licence />
			<Bureau />
			<Projet />
			<Origine />
			<Footer />
		</div>
	);
}

export default Club;
