import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col } from "react-bootstrap";
import SponsorCharot from "../images/sponsorCharot.jpg";
import SponsorGuenin from "../images/sponsorGuenin.png";
import SponsorKeepCool from "../images/sponsorKeepCool.jpg";
import SponsorEL from "../images/sponsorEL.png";
import SponsorSens from "../images/sponsorSens.jpg";
import SponsorVision from "../images/sponsorVision.jpg";
import Sponsor186 from "../images/sponsor186.png";
import SponsorAPS from "../images/sponsorAPS.png";

class Partenaire extends Component {
	render() {
		return (
			<div className="Partenaire">
				<Row>
					<Col xs={12} md={6} lg={3}>
						<a
							href="https://www.ville-sens.fr/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								className="SponsorIMG"
								alt="Sens"
								src={SponsorSens}
							/>
						</a>
					</Col>
					<Col xs={12} md={6} lg={3}>
						<a
							href="https://www.facebook.com/keepcool.sens/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								className="SponsorIMG"
								alt="KeepCool"
								src={SponsorKeepCool}
							/>
						</a>
					</Col>
					<Col xs={12} md={6} lg={3}>
						<div className="descendre"></div>
						<a
							href="https://www.charot.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								className="SponsorIMG"
								alt="Charot"
								src={SponsorCharot}
							/>
						</a>
					</Col>
					<Col xs={12} md={6} lg={3}>
						<a
							href="https://www.facebook.com/ELeclercSens/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								className="SponsorIMG"
								alt="EL"
								src={SponsorEL}
							/>
						</a>
					</Col>
				</Row>
				<Row>
					{/* <Col xs={12} md={0} lg={1}></Col> */}
					<Col xs={12} md={6} lg={3}>
						<a
							href="https://visionalamaison.fr/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								className="SponsorIMGlong"
								alt="Vision"
								src={SponsorVision}
							/>
						</a>
					</Col>
					<Col xs={12} md={6} lg={3}>
						<a
							href="http://www.186sport.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								className="SponsorIMG"
								alt="186sport"
								src={Sponsor186}
							/>
						</a>
					</Col>
					<Col xs={12} md={6} lg={3}>
					<div className="descendre"></div>
						<a
							href="https://www.aps-sens.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								className="SponsorIMGlong"
								alt="APS"
								src={SponsorAPS}
							/>
						</a>
					</Col>
					<Col xs={12} md={6} lg={3}>
						<div className="descendre"></div>
						<div className="descendre"></div>
						<a
							href="https://www.facebook.com/cyclesguenin/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								className="SponsorIMGlong"
								alt="Guenin"
								src={SponsorGuenin}
							/>
						</a>
					</Col>

					{/* <Col xs={12} md={0} lg={1}></Col> */}
				</Row>
			</div>
		);
	}
}

export default Partenaire;
