import React, { Component } from "react";
import "../App.css";
import { Row, Col } from "react-bootstrap";
import Ludovic from "../images/ludovic.jpg";
import Arnaud from "../images/arnaud.jpg";
import LudovicA from "../images/ludovica.jpg";
import Willy from "../images/willy.jpg";
import Aurele from "../images/aurele.jpg";
import Stephane from "../images/stephane.jpg";

class Bureau extends Component {
	render() {
		return (
			<div className="Bureau" id="bureau">
				<h1>Le bureau</h1>
				<hr />
				<Row className="descendre-ligne">
					<Col xs={12} md={12} lg={1}></Col>
					<Col xs={12} md={6} lg={3}>
						<img
							className="BureauIMG"
							alt="Ludovic Duhautoy"
							src={Ludovic}
						/>
						<h2>Ludovic DUHAUTOY</h2>
						<p>Président</p>
						<p>Référent école de tri et entraînements</p>
					</Col>
					{/* <Col
						xs={12}
						md={12}
						lg={2}
						className="display-none-md"
					></Col> */}
					<Col xs={12} md={6} lg={4}>
						<img
							className="BureauIMG"
							alt="Arnaud Boudier"
							src={Arnaud}
						/>
						<h2>Arnaud BOUDIER</h2>
						<p>Vice-Président</p>
						<p>
							Référent organisation épreuves et directeur des
							courses
						</p>
					</Col>
					<Col xs={12} md={6} lg={3}>
						<img
							className="BureauIMG"
							alt="Ludovic Audiard"
							src={LudovicA}
						/>
						<h2>Ludovic AUDIARD</h2>
						<p>Trésorier</p>
						<p>Référent fêtes du club</p>
					</Col>
					
					<Col xs={12} md={12} lg={1}></Col>
				</Row>
				{/* <Row className="descendre-ligne">
					<Col xs={12} md={6} lg={3}>
						<img
							className="BureauIMG"
							alt="Ludovic Audiard"
							src={LudovicA}
						/>
						<h2>Ludovic AUDIARD</h2>
						<p>Trésorier</p>
						<p>Référent fêtes du club</p>
					</Col>
					<Col xs={12} md={6} lg={3}>
						<img
							className="BureauIMG"
							alt="Gwenaëlle Stettler"
							src={Gwenaelle}
						/>
						<h2>Gwenaëlle STETTLER</h2>
						<p>Trésoriere adjointe</p>
						<p>Référente championnat du monde</p>
					</Col>
					<Col xs={12} md={6} lg={3}>
						<img
							className="BureauIMG"
							alt="Marie-Neige Locatelli"
							src={MarieNeige}
						/>
						<h2>Marie-Neige LOCATELLI</h2>
						<p>Secrétaire</p>
						<p>Référente projets féminins</p>
					</Col>
					<Col xs={12} md={6} lg={3}>
						<img
							className="BureauIMG"
							alt="Corentin Jacquet"
							src={Corentin}
						/>
						<h2>Corentin JACQUET</h2>
						<p>Secrétaire adjoint</p>
						<p>Référent communication</p>
					</Col>
				</Row> */}
				<h1>Le comité</h1>
				<hr />
				<Row className="descendre-ligne">
					<Col xs={12} md={12} lg={1}></Col>
					<Col xs={12} md={6} lg={3}>
						<img
							className="BureauIMG"
							alt="Willy Noury"
							src={Willy}
						/>
						<h2>Willy NOURY</h2>
						<p>Responsable école de triathlon</p>
					</Col>
					<Col xs={12} md={6} lg={4}>
						<img
							className="BureauIMG"
							alt="Aurèle VALLET"
							src={Aurele}
						/>
						<h2>Aurèle VALLET</h2>
						<p>Responsable communication</p>
					</Col>
					<Col xs={12} md={12} lg={3}>
						<Row>
							<Col xs={12} md={4} lg={12}></Col>
							<Col xs={12} md={4} lg={12}>
								<img
									className="BureauIMG"
									alt="Stéphane Calvet"
									src={Stephane}
								/>
								<h2>Stéphane CALVET</h2>
								<p>Responsable cohésion et convivialité</p>
							</Col>
							<Col xs={12} md={4} lg={12}></Col>
						</Row>
					</Col>
					<Col xs={12} md={6} lg={1}></Col>
				</Row>
			</div>
		);
	}
}

export default Bureau;
