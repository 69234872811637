import React, { Component } from "react";
import "../App.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

// const position = [48.198314, 3.276716];
const position = [48.169317, 3.273472];

class Leaflet extends Component {
	render() {
		return (
			<MapContainer center={position} zoom={15} /* zoom={25} */ scrollWheelZoom={true}>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<Marker position={position}>
					<Popup>
						Quai Jean Moulin <br /> 89100 Sens
					</Popup>
				</Marker>
			</MapContainer>
		);
	}
}

export default Leaflet;
