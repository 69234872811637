import React from "react";
import "./Error404.css";
import "bootstrap/dist/css/bootstrap.min.css";

function Error404() {
	var themeRec = localStorage.getItem("theme");
	var theme2 = themeRec.substr(1);
	var theme = theme2.substring(0, theme2.length - 1);

	return (
		<div className="Error404" data-theme={theme}>
			<h1>Erreur 404</h1>
			<p>La page que vous cherchez n'existe pas !</p>
			<a href="/">Retourner sur le site</a>
		</div>
	);
}

export default Error404;
