import React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Footer from "./Footer";
import { Card, Row, Col } from "react-bootstrap";

function Mentions() {
	var themeRec = localStorage.getItem("theme");
	var theme2 = themeRec.substr(1);
	var theme = theme2.substring(0, theme2.length - 1);

	return (
		<div className="Mentions" data-theme={theme}>
			<Header />
			<div className="OneCard">
				<Card className="CardStyle Card-ombre">
					<Card.Body>
						<Row>
							<Col xs={12} md={12} lg={4}>
								<Card.Title className="titleMentions">
									Mentions légales
								</Card.Title>
								<Card.Subtitle className="subtitleMentions">
									Site :
								</Card.Subtitle>
								<Card.Text className="textMentions">
									<a
										href="https://www.sens-triathlon.com"
										className="mentions"
									>
										www.sens-triathlon.com
									</a>
								</Card.Text>
								<Card.Subtitle className="subtitleMentions">
									Éditeur :
								</Card.Subtitle>
								<Card.Text className="textMentions">
									Antoine LAIR et bureau Sens Triathlon
								</Card.Text>
								<Card.Subtitle className="subtitleMentions">
									Concepteur :
								</Card.Subtitle>
								<Card.Text className="textMentions">
									<a
										href="http://lairantoine.fr"
										className="mentions"
										target="_blank"
										rel="noopener noreferrer"
									>
										Antoine LAIR
									</a>
								</Card.Text>
								<Card.Subtitle className="subtitleMentions">
									Adresse du webmaster :
								</Card.Subtitle>
								<Card.Text className="textMentions">
									<a
										href="mailto:dev.senstri@gmail.com"
										className="mentions"
									>
										dev.senstri@gmail.com
									</a>
								</Card.Text>
								<Card.Subtitle className="subtitleMentions">
									Hébergeur :
								</Card.Subtitle>
								<Card.Text className="textMentions">
									OVH (
									<a
										href="https://www.ovhcloud.com/fr/"
										className="mentions"
										target="_blank"
										rel="noopener noreferrer"
									>
										ovhcloud.com
									</a>
									)
								</Card.Text>
								<Card.Subtitle className="subtitleMentions">
									Mise en garde générale :
								</Card.Subtitle>
								<Card.Text className="justify textMentions">
									Nos services mettent tout en œuvre pour
									offrir aux visiteurs de ce site web des
									informations fiables, exacts et actualisées.
									Cependant, malgré tous les soins apportés,
									le site peut comporter des inexactitudes,
									des défauts de mise à jour ou des erreurs.
									Nous remercions les utilisateurs du site de
									nous faire part d’éventuelles omissions,
									erreurs ou corrections par mail sur la boite
									aux lettres du webmaster.
								</Card.Text>
							</Col>
							<Col xs={12} md={12} lg={8}>
								<Card.Subtitle className="subtitleMentions">
									Droits d’auteurs :
								</Card.Subtitle>
								<Card.Text className="justify textMentions">
									Le présent site constitue une œuvre dont
									l’Editeur est l’auteur au sens des articles
									L. 111.1 et suivants du Code de la propriété
									intellectuelle. Les photographies, textes,
									logos, pictogrammes, ainsi que toutes œuvres
									intégrées dans le site sont la propriété de
									l’Editeur ou de tiers ayant autorisé
									l’Editeur à les utiliser. Les reproductions,
									les transmissions, les modifications, les
									réutilisations, sur un support papier ou
									informatique, dudit site et des œuvres qui y
									sont reproduites ne sont autorisées que pour
									un usage personnel et privé conforme aux
									dispositions de l’article L 122-5 du Code de
									la Propriété Intellectuelle. Ces
									reproductions devront ainsi notamment
									indiquer clairement la source et l’auteur du
									site et/ou de ces œuvres multimédias. En
									aucun cas ces reproductions ne sauraient
									porter préjudice aux droits des tiers. Les
									reproductions, les transmissions, les
									modifications, les réutilisations à des fins
									publicitaires, commerciales ou
									d’information, de tout ou partie du site,
									sont totalement interdites.
								</Card.Text>
								<Card.Subtitle className="subtitleMentions">
									Etablissements de liens vers le site :
								</Card.Subtitle>
								<Card.Text className="justify textMentions">
									L’Editeur autorise la mise en place d’un
									lien hypertexte vers son site pour tous les
									sites Internet, à l’exclusion de ceux
									diffusant des informations à caractère
									polémique, pornographique, xénophobe ou
									pouvant, dans une plus large mesure porter
									atteinte à la sensibilité du plus grand
									nombre. Le lien doit aboutir à la page
									d’accueil du site et le site doit apparaître
									dans une nouvelle fenêtre. Les pages du site
									ne doivent en aucun cas être intégrées à
									l’intérieur des pages d’un autre site. La
									demande d’établissement de lien hypertexte
									doit être faite par courrier électronique au
									webmaster. Dans tous les cas d’espèce,
									l’Editeur se réserve le droit de demander la
									suppression d’un lien si elle estime que le
									site cible ne respecte pas les règles ainsi
									définies. Liens vers des sites tiers depuis
									le site : Les liens hypertextes mis en œuvre
									au sein du site en direction d’autres sites
									et/ou de pages personnelles et d’une manière
									générale vers toutes ressources existantes
									sur internet, ainsi que leurs contenus, ne
									sauraient engager la responsabilité de
									l’Editeur quant aux liens qu’ils contiennent
									ou aux changements ou mises à jour qui leur
									sont apportés.
								</Card.Text>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</div>
			<Footer />
		</div>
	);
}

export default Mentions;
