import React from "react";
import "../App.css";
import { Row, Col, Table } from "react-bootstrap";
import Aurele from "../images/aurele.jpg";
import LudovicA from "../images/ludovica.jpg";
import Julien from "../images/julien.jpg";
import Course from "../images/course.png";
import CourseDark from "../images/coursedark.png";
import Velo from "../images/velo.png";
import VeloDark from "../images/velodark.png";
import Natation from "../images/natation.png";
import NatationDark from "../images/natationdark.png";
import Renfo from "../images/renfo.png";
import RenfoDark from "../images/renfodark.png";
import KeepCool from "../images/keepcool.svg";

function Adultes() {
	var themeRec = localStorage.getItem("theme");
	var theme2 = themeRec.substr(1);
	var theme = theme2.substring(0, theme2.length - 1);

	return (
		<div className="Adultes" id="adultes">
			<h1>Nos entraînements pour les adultes (2023-2024)</h1>
			<hr />
			<Table className="tableau-responsive" responsive>
				<thead>
					<tr>
						<th className="all-border">Jour</th>
						<th className="blue-back all-border">Lundi</th>
						<th className="pink-back all-border">Mardi</th>
						<th className="blue-back all-border">Mercredi</th>
						<th className="pink-back all-border">Jeudi</th>
						<th className="blue-back all-border">Vendredi</th>
						
						<th className="pink-back all-border">Dimanche</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="blue-text side-border vertical"></td>
						<td
							colspan="2"
							className="blue-text all-border vertical"
						>
							<img
								height="35px"
								className="petite-image"
								alt="Natation"
								src={theme === "dark" ? NatationDark : Natation}
							/>
							Accès GRATUIT centre nautique <br />11h30-14h (évacuation 13h40)
						</td>
						<td className="blue-text side-border vertical"></td>
						<td
							colspan="2"
							className="blue-text all-border vertical"
						>
							<img
								height="35px"
								className="petite-image"
								alt="Natation"
								src={theme === "dark" ? NatationDark : Natation}
							/>
							Accès GRATUIT centre nautique <br />11h30-14h (évacuation 13h40)
						</td>
						
						<td className="blue-text side-border vertical"></td>
					</tr>
					<tr>
						<td className="vertical bold side-border" rowSpan="2">
							Discipline
						</td>
						<td className="vertical side-border">
							<img
								height="45px"
								className="petite-image"
								alt="Natation"
								src={theme === "dark" ? NatationDark : Natation}
							/>
						</td>
						<td className="vertical side-border">
							<img
								height="45px"
								className="petite-image"
								alt="Natation"
								src={theme === "dark" ? NatationDark : Natation}
							/>
						</td>
						<td className="vertical side-border">
						<img
								height="45px"
								className="petite-image"
								alt="Course"
								src={theme === "dark" ? CourseDark : Course}
							/>
							<img
								height="45px"
								className="petite-image"
								alt="Renfo"
								src={theme === "dark" ? RenfoDark : Renfo}
							/>
						</td>
						<td className="vertical side-border">
							<img
								height="45px"
								className="petite-image"
								alt="Course"
								src={theme === "dark" ? CourseDark : Course}
							/>
						</td>
						<td className="vertical side-border">
							<img
								height="45px"
								className="petite-image"
								alt="Natation"
								src={theme === "dark" ? NatationDark : Natation}
							/>
						</td>
						
						<td className="vertical side-border">
							<img
								height="45px"
								className="petite-image"
								alt="Velo"
								src={theme === "dark" ? VeloDark : Velo}
							/>
						</td>
					</tr>
					<tr>
						<td className="blue-text side-border vertical">
							Natation
						</td>
						<td className="pink-text side-border vertical">
							Natation
						</td>
						<td className="blue-text side-border vertical">
							Footing + PPG / Renfo
						</td>
						<td className="pink-text side-border vertical">
							CàP
						</td>
						<td className="blue-text side-border vertical">
							Natation
						</td>
						
						<td className="blue-text side-border vertical">Vélo</td>
					</tr>
					<tr>
						<td className="bold side-border">Horaire</td>
						<td className="blue-text side-border vertical">
							20h - 21h
						</td>
						<td className="pink-text side-border vertical">
							20h - 21h
						</td>
						<td className="blue-text side-border vertical">
							18h - 19h30
						</td>
						<td className="pink-text side-border vertical">
							18h30 - 20h
						</td>
						<td className="blue-text side-border vertical">
							18h30 - 19h30 (Collège)
							<br />
							19h45 - 21h (Adulte)
						</td>
						
						<td className="blue-text side-border vertical">
							9h (Adulte)
							<br />
							9h30 (Jeunes / Débutants)
							</td>
					</tr>
					<tr>
						<td className="bold  side-bottom-border">Lieu</td>
						<td className="blue-text  side-bottom-border vertical">
							Centre nautique
							(Compétition)
						</td>
						<td className="pink-text  side-bottom-border vertical">
							Centre nautique
						</td>
						<td className="blue-text  side-bottom-border vertical">
							<img
								height="20px"
								className=""
								alt="KeepCool"
								src={KeepCool}
							/>
						</td>
						<td className="pink-text  side-bottom-border vertical">
						
							Stade Claude Pitou
						</td>
						<td className="blue-text  side-bottom-border vertical">
							Centre nautique
						</td>
						
						<td className="blue-text  side-bottom-border vertical">
							Parking Centre nautique
						</td>
					</tr>
					
				</tbody>
			</Table>
			<div className="Ecole">
				<Row>
					<Col xs={12} md={12} lg={1}></Col>
					<Col xs={12} md={6} lg={3}>
						<img
							className="BureauIMG"
							alt="Aurele VALLET"
							src={Aurele}
						/>
						<h2>Aurele VALLET</h2>
						<p className="pink">Coordonnateur entraînement</p>
					</Col>
					<Col xs={12} md={6} lg={4}>
						<img
							className="BureauIMG"
							alt="Ludovic Audiard"
							src={LudovicA}
						/>
						<h2>Ludovic AUDIARD</h2>
						<p className="pink">Coach course à pied</p>
					</Col>
					<Col xs={12} md={12} lg={3}>
						<Row>
							<Col xs={12} md={4} lg={12}></Col>
							<Col xs={12} md={4} lg={12}>
								<img
									className="BureauIMG"
									alt="Julien Groseiller"
									src={Julien}
								/>
								<h2>Julien GROSEILLER</h2>
								<p className="pink">
									Coach Keep Cool et partenaire du club
								</p>
							</Col>
							<Col xs={12} md={4} lg={12}></Col>
						</Row>
					</Col>
					<Col xs={12} md={12} lg={1}></Col>
				</Row>
			</div>
		</div>
	);
}

export default Adultes;
