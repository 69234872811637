import React, { Component } from "react";
import "../App.css";
import { Card } from "react-bootstrap";

class Origine extends Component {
	render() {
		return (
			<div className="Origine" id="origines">
				<h1>Origine de Sens Triathlon</h1>
				<hr />
				<div className="OneCard">
					<Card className="CardStyle2 Card-ombre">
						<Card.Body>
							<Card.Text className="textMentions justify">
								<div className="alinea">
									C'est à l'initiative d'une bande de copains
									(Daniel Finot, Thierry Couzon, Philippe
									Léongue et Jean-Jacques Ragon) qui, faisant
									du sport ensemble (athlétisme), ont
									participé à quelques épreuves enchaînées
									(notamment le triathlon AJA organisé à
									Sens).
								</div>
								<br />
								<div className="alinea">
									Créée en 1989 au sein du Club de Natation de
									Sens, la section Triathlon, qui devient SENS
									TRIATHLON en 1999, a pour ambition première
									de permettre à tous les publics, hommes,
									femmes, et enfants, la pratique en loisir et
									en compétition, de ces disciplines
									enchaînées.
								</div>
								<br />
								<div className="alinea">
									Pour atteindre cet objectif, les dirigeants
									successifs se sont toujours efforcés de
									proposer des créneaux d’entraînements
									nombreux (grâce au soutien de la ville de
									Sens et de la Communauté d'Agglomération du
									Grand Sénonais), encadrés par des
									entraîneurs diplômés.
								</div>
								<br />
								<div className="alinea">
									Différents présidents se sont succédés, à
									savoir : José Brunel, Alain Ruscon, Philippe
									Chevalier, Jocelyn Godard, Philippe
									Toussaint, Jocelyn Godard et Ludovic
									Duhautoy.
								</div>
							</Card.Text>
						</Card.Body>
					</Card>
				</div>
			</div>
		);
	}
}

export default Origine;
