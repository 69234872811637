import React from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../components/Header";
import Contactform from "../components/Contactform";
import Footer from "../components/Footer";

function Contact() {
	var themeRec = localStorage.getItem("theme");
	var theme2 = themeRec.substr(1);
	var theme = theme2.substring(0, theme2.length - 1);

	return (
		<div className="App" data-theme={theme}>
			<Header />
			<Contactform />
			<Footer />
		</div>
	);
}

export default Contact;
